<template>
  <div class="rank">
    <div class="header">
      <!-- <van-nav-bar
        title
        left-text
        left-arrow
        :fixed="false"
        :border="false"
        :safe-area-inset-top="true"
        @click-left="onClickLeft"
      /> -->
      <div class="desc">
        <img class="text" src="../../public/statics/images/rank/rank.png" />
        <p>数据更新:{{timestampToTime(new Date().toLocaleDateString(), 1)}}</p>
      </div>
    </div>
    <section class="rankMenu">
      <img v-authCheck="{ type: 4 }" v-if="role.quyu" class="analysis" @click="toPath('/analysis',{})" src="../../public/statics/images/my/fenxi.png" />
      <img class="rw" v-show="rwShow" @click="toPath('/myRenWu',{})" src="../../public/statics/images/my/myrenwu.png" alt />
    </section>
    <div class="cont">
      <ul class="rankTab">
        <li :class="[active == 1 ? 'on' : '']" @click="changeactive(1)">发声排行榜</li>
        <li :class="[active == 2 ? 'on' : '']" @click="changeactive(2)">任务排行榜</li>
      </ul>
      <van-tabs
        v-model:active="activeName"
        title-inactive-color="#333333"
        title-active-color="#0B47AC"
        color="#0B47AC"
        @click-tab="onClickTab"
      >
        <van-tab title="周" name="1"></van-tab>
        <van-tab title="月" name="2"></van-tab>
        <van-tab title="季度" name="3"></van-tab>
        <van-tab title="年" name="4"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <!-- 自己当前排名 -->
        <div class="info" v-if="active == 1 && selfRank">
          <div>
            <!-- <img class="pic" src="../../public/statics/images/rank/avator.jpg" /> -->
            <img class="pic" :src="adddom(selfRank.speaker.thumb)" />
            {{ selfRank && selfRank.speaker?selfRank.speaker.name:""}}
          </div>
          <div>
            当前排名
            <span>{{selfRank ? selfRank.rank : ''}}</span>
          </div>
          <div>
            传播值
            <span>{{selfRank ? selfRank.score :''}}</span>
          </div>
        </div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="rankList.length>0 ? '没有更多了' : '暂无数据'"
          @load="getList"
        >
          <div
            class="rankItem"
            v-for="(item, idx) in rankList"
            :key="idx"
            v-if="active == 1&&rankList.length>0"
          >
            <div class="Indexes" v-if="idx+1<4">
              <img v-if="item.rank ==1" src="../../public/statics/images/rank/t1.png" />
              <img v-if="item.rank ==2" src="../../public/statics/images/rank/t2.png" />
              <img v-if="item.rank ==3" src="../../public/statics/images/rank/t3.png" />
            </div>
            <div class="Indexes" v-else>{{item.rank}}</div>
            <div>
              <!-- <img src="../../public/statics/images/rank/avator.jpg" /> -->
              <img style="border-radius: 50%;" :src="adddom(item.speaker.thumb)" />
              {{item.speaker?item.speaker.name:""}}
            </div>
            <div>
              <img src="../../public/statics/images/rank/start.png" />
              {{item?item.score:""}}
            </div>
          </div>

          <div class="rankItem" @click="toPath('/taskDetail',{id: item.duty.id})" v-for="(item, idx) in rankList" :key="idx" v-if="active == 2">
            <div class="Indexes" v-if="idx+1<4">
              <img v-if="item.rank ==1" src="../../public/statics/images/rank/t1.png" />
              <img v-if="item.rank ==2" src="../../public/statics/images/rank/t2.png" />
              <img v-if="item.rank ==3" src="../../public/statics/images/rank/t3.png" />
            </div>
            <div class="Indexes" v-else>{{item.rank}}</div>
            <div>{{item.duty ? item.duty.title : ''}}</div>
            <div>
              <img src="../../public/statics/images/rank/start.png" />
              {{item.score}}
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <index-footer></index-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import utils from '@/api/Utils'
export default {
  name: "rank",
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      rwShow: false,
      role: {
        quyu:false
      },
      refreshing: false, //下拉刷新
      rankList: [], //排行榜列表
      selfRank: {
        score: "",
        rank: "",
        speaker: {
          name: ""
        }
      }, //自己当前排名
      active: 1,
      activeName: "1",
      list: [],
      loading: false,
      finished: false,
      params: {
        cate_id: "",
        page: 1,
        size: 500
      },
      getCodeUrl:"http://speaker.shanmuzhi.com/api/wxcode?url=my",
      memberUrl: "http://speaker.shanmuzhi.com/#/",
    };
  },
  components: {
    indexFooter
  },
  activated() {
    this.role.quyu = false
    // 权限控制
    this.roleCon()
    this.memberInfo()
  },
  created() {
    this.role.quyu = false
    this.memberInfo()
    this.getCodeUrl = this.$publicData.domain + 'api/wxcode?url=rank'
    this.memberUrl = this.$publicData.domain + '#/'
    let that = this
    var token = Cookies.get('spToken');
    if (token && token != undefined) {
      // 有token 直接展示
      that.roleCon()
      that.getList();
      return;
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
        var code = codeArr["code"];
        var params = {
            code: code
        };
        // 绑定
        this.$api.index.wxlogin(params).then(res => {
            if (res.code == 200) {
                Cookies.set('spToken', res.result.token, { expires: 365 })
                localStorage.setItem('priv', JSON.stringify(res.result.priv))
                if (res.result.location_status == 0) {
                  that.getLocation();
                }
                // 权限控制
                that.roleCon()
                that.getList();
            }
        });
    }else{
      window.location.href = this.getCodeUrl;
    }
  },
  mounted() {
    this.shareFun()
    $(document).unbind().on("touchstart",".rankMenu", function(e) {
        $('body').css({'overflow':'hidden'})
        var e = e || window.event;
        console.log(e);
        var positionDiv = $(this).offset();
        var scrollTop = window.pageYOffset //用于FF
          || document.documentElement.scrollTop
          || document.body.scrollTop
          || 0;
        console.log(scrollTop)
        var distenceY =
          e.originalEvent.targetTouches[0].pageY - positionDiv.top + scrollTop
        $(document).on("touchmove", function(e) {
          var e = e || window.event;
          var y = e.originalEvent.targetTouches[0].pageY - distenceY;
          let height = $(".header").outerHeight(true) + 15;
          if (y < height) {
            y = height;
          } else if (y > $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rankMenu').outerHeight(true) - 15) {
            y = $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rankMenu').outerHeight(true) - 15
          }

          $(".rankMenu").css({
            top: y + "px"
          })
        });
        $(".rankMenu").on("touchend", function(e) {
          $('body').css({'overflow':'auto'})
          $(document).unbind("touchmove");
        });
      });
  },
  methods: {
    memberInfo() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.index.memberInfo(ps).then(function(response) {
        if (response.code === 200) {
          localStorage.setItem('priv', JSON.stringify(response.result.priv))
          setTimeout(() => {
            that.roleCon()
          }, 300);
          // 不是发生人不显示，我的任务悬浮按钮
          if (response.result.speaker_id == 0 && response.result.speaker == null) {
            that.rwShow = false
            // that.toPath('/apply',{})
          } else if (response.result.speaker && response.result.speaker.status == 1){
            that.rwShow = true
          }
        }
      })
    },
    shareFun() {
        var that = this;
        var tempurl = location.href;
        const url = location.href.split("#")[0];
        console.log("Iframe ---share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.index.getShare({url: tempurl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // console.log('Iframe ---微信分享设置---开始');
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                    // console.log('Iframe ---微信分享设置---结束');
                });
            }
        });
    },
    roleCon(){
      let priv = JSON.parse(localStorage.getItem('priv')) || []
      if(priv.length>0){
        let arr = []
        priv.forEach(function(item,idx){
          arr.push(item.right.id)
        })
        if (arr.indexOf(4) != -1){
          this.role.quyu = true
        } else {
          this.role.quyu = false
        }
      }
    },
    // 截取url中的code方法
    getUrlCode() {
        var allUrl = location.href;
        var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
        this.winUrl = url;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest;
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    timestampToTime (timestamp, type) {
      return utils.timestampToTime(timestamp, type)
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.rankList = [];
      that.loading = true;
      that.finished = false;
      that.params.page = 1;
      that.getList();
    },
    changeactive(num) {
      var that = this;
      that.active = num;
      that.activeName = "1";
      that.rankList = [];
      that.loading = true;
      that.finished = false;
      that.getList();
    },
    getList() {
      let token = Cookies.get("spToken")
      if (!token){
        return
      }
      var that = this;
      that.loading = true;
      var params = {
        token: Cookies.get("spToken"),
        type: that.active,
        date_type: that.activeName
      };
      that.$api.index.rankList(params).then(res => {
        if (res.code == 200) {
          that.rankList = res.result.list;
          that.selfRank = res.result.self_rank;
        }
        // 加载状态结束
        that.loading = false;
        that.finished = true;
      });
    },
    onClickTab(item) {
      let that = this
      // console.log(item);
      that.rankList = [];
      that.loading = true;
      that.finished = false;
      this.getList();
    },
    onLoad() {
      let that = this;
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          that.rankList.push(that.rankList.length + 1);
        }
        // 加载状态结束
        that.loading = false;

        // 数据全部加载完成
        if (that.rankList.length >= 40) {
          that.finished = true;
        }
      }, 1000);
      // 异步更新数据
      // let that = this
      // that.$api.index.getNewsList(that.params).then(function (response) {
      //   if (response.code === 200) {
      //     that.list = that.list.concat(response.result.list)
      //     that.params.page++
      //     // 加载状态结束
      //     that.loading = false
      //     // 数据全部加载完成
      //     // console.log(response.result.list.length)
      //     if (response.result.list.length < 1) {
      //       that.finished = true
      //     }
      //   }
      // })
    },
    getLocation() {
      var that = this;
      var tempurl = location.href;
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          wx.ready(function() {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed; // 速度，以米/每秒计
                // var accuracy = res.accuracy; // 位置精度
                // console.log(res)
                console.log('获取成功')
                let locationData = {
                  token: Cookies.get("spToken"),
                  lat: latitude,
                  lng: longitude
                }
                that.$api.index.speakerEditArea(locationData).then(res => {
                  if (res.code == 200) {
                    // 更改
                  }
                })
              }
            })
          })
        }
      })
    }
  }
};
</script>
<style scoped>
.rank .rw{
  width: 1.89rem;
}
.rank .rankMenu{
  position: fixed;
  z-index: 100;
  top: 7rem;
  right: 0;
}
.rank .analysis {
  display: block;
  width: 2.02rem;
  height: 2.32rem;
  margin-bottom: 0.3rem;
}
</style>
