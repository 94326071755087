<template>
  <div class="personal">
    <div class="lanback">
      <!-- 个人信息 -->
      <div class="userinfo">
        <div class="infotop">
          <div class="user" style="position:relative;flex:1;">
            <div class="thumb" @click="dialogShow = true">
              <img :src="adddom(myInfo && myInfo.thumb)" alt />
              <div class="picEdit">更换</div>
            </div>
            <img v-if="myInfo.speaker_sptag && myInfo.speaker_sptag.img" style="position: absolute;bottom:0.3rem;left:1.29rem;width:0.3rem;height: 0.4rem;" :src="adddom(myInfo && myInfo.speaker_sptag && myInfo.speaker_sptag.img)" alt />
            <!-- <img src="../../public/statics/images/index/avator.png" alt=""> -->
            <div class="desc" style="">
              <span style="display: inline-flex;position:relative;"><em style="flex: 1;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{myInfo.name}}</em><span v-show="myInfo.speaker_sptag && myInfo.speaker_sptag.name" style="position:relative;bottom:-0.15rem;font-size:12px;color:#ff7428;height: 0.5rem;text-align: center; line-height: 0.5rem;margin-left: 0.1rem;padding: 0px 8px;border-radius: 20px;background-color: #ffe9be;">{{myInfo && myInfo.speaker_sptag && myInfo.speaker_sptag.name}}</span></span>
              <span>{{myInfo.phone}}</span>
            </div>
          </div>
          <div class="jiang" @click="toPath('/share',{speaker_id: myInfo.id})">
            <!-- <img src="../../public/statics/images/my/jiang.png" alt /> -->
            <img
              style="border-radius: 0;"
              :src="adddom(myInfo.show_role && myInfo.show_role.thumb)"
              alt
            />
            <span>{{myInfo.show_role && myInfo.show_role.name}}</span>
          </div>
        </div>
        <div class="infobottom">
          <div class="infoBox" @click="toPath('/detailed',{})">
            <img src="../../public/statics/images/my/ji.png" alt />
            <span>积分: {{myInfo.score}}</span>
          </div>
          <div class="infoBox" @click="toPath('/shopmall',{})">
            <img src="../../public/statics/images/my/shop.png" alt />
            <span>积分商城</span>
          </div>
        </div>
      </div>
      <!-- 发布文章 -->
      <div class="release">
        <span>发布文章领积分</span>
        <van-button @click="toPublic" class="releaseBtn" round icon="plus" type="primary">发布</van-button>
        <div class="control" v-show="false">您的权限等级过低,不能进行发布文章</div>
      </div>
      <van-tabs
        title-active-color="#0B47AC"
        title-inactive-color="#333"
        @change="onClickTab"
        line-width="30px"
        v-model:active="active"
      >
        <van-tab v-authCheck="{ type: 2 }" v-if="role.showYuanchuang" title="我的原创" name="1"></van-tab>
        <van-tab v-authCheck="{ type: 3 }" v-if="role.showZhuanfa" title="我的转发" name="2"></van-tab>
        <van-tab v-authCheck="{ type: 1 }" v-if="role.showTask" title="我的任务" name="3"></van-tab>
        <van-tab v-authCheck="{ type: 6 }" v-if="role.showFabu" title="我的发布" name="4"></van-tab>
      </van-tabs>
    </div>
    <!-- 文章 -->
    <!-- 没有权限时展示 -->
    <div class="no-check" v-show="false">
      <img src="../../public/statics/images/check.png" />
      <h1>您的权限等级过低,暂未开启此功能</h1>
    </div>
    <!-- 列表展示 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        class="article"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
      >
        <template v-for="item in list" :key="item">
          <div class="articleItem" v-if="active ==1">
            <div class="artTop" @click="toPath('/myDetail',{id:item.id, type:1})">
              <div>
                <img :src="adddom(item.thumb)" alt />
              </div>
              <div>
                <h1>{{item.title}}</h1>
                <p>{{item.content}}</p>
              </div>
            </div>
            <div class="state" v-show="item.status == -1">
              <div class="desc">
                文章状态：
                <van-tag type="primary" v-show="item.status == 0">待审核</van-tag>
                <van-tag type="danger" v-show="item.status == -1">驳回</van-tag>
                <van-tag type="success" v-show="item.status == 1">通过</van-tag>
              </div>
              <van-button type="primary" size="mini" @click="toPath('/articlePub',{id:item.id})">修改</van-button>
            </div>
            <div class="artBot" @click="toPath('/myDetail',{id:item.id, type:1})">
              <p>
                <img :src="adddom(item.speaker?item.speaker.thumb:'')" alt />
                {{item.speaker?item.speaker.name:''}}
              </p>
              <span>{{item.created_at}}</span>
            </div>
          </div>

          <div
            @click="toPath('/myDetail',{id:item.id, type:2})"
            class="articleItem"
            v-if="active ==2"
          >
            <div class="artTop">
              <div>
                <img :src="adddom(item.speaker_article.thumb)" alt />
              </div>
              <div>
                <h1>{{item.speaker_article.title}}</h1>
                <p>{{item.speaker_article.content}}</p>
              </div>
            </div>
            <div class="artBot">
              <p>
                <img
                  :src="adddom(item.speaker_article.speaker?item.speaker_article.speaker.thumb:'')"
                  alt
                />
                {{item.speaker_article.speaker?item.speaker_article.speaker.name:''}}
              </p>
              <span>{{item.created_at}}</span>
            </div>
          </div>

          <div
            @click="toPath('/myDetail',{id:item.id, type:3})"
            class="articleItem"
            v-if="active ==3"
          >
            <div class="artTop">
              <div>
                <img :src="adddom(item.duty.thumb)" alt />
              </div>
              <div>
                <h1>{{item.duty.title}}</h1>
                <p>{{item.duty.describe}}</p>
              </div>
            </div>
            <div class="artBot">
              <p>
                <img :src="adddom(item.speaker?item.speaker.thumb:'')" alt />
                {{item.speaker?item.speaker.name:''}}
              </p>
              <span>{{item.created_at}}</span>
            </div>
          </div>
          <div
            @click="toPath('/taskDetail',{id:item.id})"
            class="articleItem fabu"
            v-if="active ==4"
          >
            <div class="artTop">
              <div>
                <img :src="adddom(item.thumb)" alt />
              </div>
              <div>
                <h1>{{item.title}}</h1>
                <p>{{item.describe}}</p>
              </div>
            </div>
            <!-- <div class="artBot">
              <p>
                <img :src="adddom(item.speaker?item.speaker.thumb:'')" alt />
                {{item.speaker?item.speaker.name:''}}
              </p>
              <span>{{item.created_at}}</span>
            </div>-->
          </div>
        </template>
      </van-list>
      <van-empty
        class="custom-image"
        v-if="!loading && list.length==0 && active == 1"
        :image="require('../../public/statics/images/my/none.png')"
        description="暂无原创内容"
      />
      <van-empty
        class="custom-image"
        v-if="!loading && list.length==0 && active == 2"
        :image="require('../../public/statics/images/my/none.png')"
        description="暂无转发内容"
      />
      <van-empty
        class="custom-image"
        v-if="!loading && list.length==0 && active == 3"
        :image="require('../../public/statics/images/my/none.png')"
        description="暂无发声内容"
      />
      <van-empty
        class="custom-image"
        v-if="!loading && list.length==0 && active == 4"
        :image="require('../../public/statics/images/my/none.png')"
        description="暂无发布内容"
      />
    </van-pull-refresh>
    <section v-if="false" v-show="myInfo.is_authentication == 0" class="authentication" @click="toPath('/authentication',{})">
      <img src="../../public/statics/images/my/renzheng.png" />
    </section>
    <!-- 底部导航 -->
    <index-footer></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="loadRole" style="z-index:900;">
      <div class="roleChange-wrapper" @click.stop>
        <div class="roleChange-block">
          <img class="avator" :src="adddom(myInfo.thumb)" />
          <h1>{{myInfo.name}}</h1>
          <h2>
            恭喜您获得
            <br />
            「{{roleData.after.name}}」称号
          </h2>
        </div>
        <img
          class="roleChange-close"
          @click="loadRole = false"
          src="../../public/statics/images/my/close.png"
        />
      </div>
    </van-overlay>
    <!--  -->
    <van-overlay :show="dialogShow" style="z-index:110;">
      <div class="change-wrapper" @click.stop>
        <div class="cont">
          <img class="pic" :src="adddom(myInfo && myInfo.thumb)" alt />
          <!-- <div class="choice">相册选择</div> -->
          <van-uploader ref="upload" multiple :before-read="beforeRead" :max-count="1" :after-read="afterRead" :before-delete="handleRemove">
            <van-button  class="choice" :loading="upModel" type="primary" size="large">相册选择</van-button>
          </van-uploader>
          <van-icon class="close" @click="dialogShow = false" name="close" />
        </div>
      </div>
    </van-overlay>
    <div class="upModel" v-show="upModel">
      <van-loading size="24px" color="#ffffff">图片上传中...</van-loading>
    </div>
  </div>
</template>
<style lang="css" scoped>
  .personal .authentication {
    width:2.1rem;
    height:2.38rem;
    position: fixed;
    z-index: 100;
    top: 7rem;
    right: 0;
}
.personal .change-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.personal .change-wrapper .cont {
  width: 7.96rem;
  position: relative;
  border-radius: 6px;
  background: #fff;
  border-radius: 5px;
}
.personal .change-wrapper .cont .pic{
  width: 7.96rem;
  height: 7.72rem;
  object-fit: cover;
  border-radius: 5px;
}
.personal .change-wrapper .cont .choice{
  font-size: 0.42rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 7.96rem;
  text-align: center;
  height: 2rem;
  background: transparent;
  border: none;
}
.personal .change-wrapper .cont .close{
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
  font-size: 0.8rem;
}
</style>

<script>
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      dialogShow:false,
      fileList: [],
      alum: [],
      upModel: false,
      role: {
        showTask: false,
        showFabu: false,
        showFabuBtn: false
      }, //权限显示
      roleData: "", //角色变化的数据
      loadRole: false, //角色变化后的探测提示
      active: "1",
      loadShow: false,
      refreshing: false, //下拉刷新
      list: [],
      loading: false,
      finished: false,
      params: {
        type: "",
        page: 1,
        size: 10
      },
      myInfo: "",
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode?url=my",
      memberUrl: "http://speaker.shanmuzhi.com/#/"
    };
  },
  components: {
    indexFooter
  },
  created() {
    // 权限控制
    this.roleCon();
    // 分享
    this.getCodeUrl = this.$publicData.domain + "api/wxcode?url=my";
    this.memberUrl = this.$publicData.domain + "#/";
    let that = this;
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      // 有token 直接展示
      that.memberInfo();

      this.active = this.$route.query.type;
      this.loadShow = true;
      // 权限控制
      this.roleCon();
      this.getInfo();
      this.roleChangeLog();
      return;
    }
    if (this.$route.query.type != undefined) {
      Cookies.set("type", this.$route.query.type);
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
      var code = codeArr["code"];
      var params = {
        code: code
      };
      // 绑定
      this.$api.index.wxlogin(params).then(res => {
        if (res.code == 200) {
          Cookies.set("spToken", res.result.token, { expires: 365 });
          localStorage.setItem("priv", JSON.stringify(res.result.priv));
          if (res.result.location_status == 0) {
            that.getLocation();
          }
          // 权限控制
          this.roleCon();
          that.active = Cookies.get("type");
          Cookies.set("type", "");
          that.loadShow = true;
          that.memberInfo();
          that.getInfo();
          that.roleChangeLog();
          that.onLoad();
        }
      });
    } else {
      window.location.href = this.getCodeUrl;
    }
  },
  mounted() {
    $(document).unbind().on("touchstart",".authentication", function(e) {
        $('body').css({'overflow':'hidden'})
        var e = e || window.event;
        console.log(e);
        var positionDiv = $(this).offset();
        var scrollTop = window.pageYOffset //用于FF
          || document.documentElement.scrollTop
          || document.body.scrollTop
          || 0;
        console.log(scrollTop)
        var distenceY =
          e.originalEvent.targetTouches[0].pageY - positionDiv.top + scrollTop
        $(document).on("touchmove", function(e) {
          var e = e || window.event;
          var y = e.originalEvent.targetTouches[0].pageY - distenceY;
          let height = $(".header").outerHeight(true) + 15;
          if (y < height) {
            y = height;
          } else if (y > $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.authentication').outerHeight(true) - 15) {
            y = $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.authentication').outerHeight(true) - 15
          }

          $(".authentication").css({
            top: y + "px"
          })
        });
        $(".authentication").on("touchend", function(e) {
          $('body').css({'overflow':'auto'})
          $(document).unbind("touchmove");
        });
      });
  },
  activated() {
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      this.getInfo();
      this.memberInfo();
    }
  },
  methods: {
    beforeRead (file) {
      // 上传前处理
      // console.log(file)
      if (typeof file === 'object' && file.constructor === Array) {
        if (file.length > 1) {
          Toast('请最多上传1张照片')
          return false
        }
        if (file.length < 1) {
          Toast('请上传照片')
          return false
        }
      }
      return true
    },
    afterRead (file) {
      let that = this
      console.log(file)
      console.log(typeof file === 'object' && file.constructor === Array)
      that.upModel = true
      // file.status = 'uploading'
      // file.message = '上传中...'
      // 此时可以自行将文件上传至服务器
      // console.log(file)
      let parse = {
        imgFile: file.content
      }
      console.log(99999)
      console.log(parse.imgFile)
      this.$api.my.uploadBase64(parse).then(function (response) {
        if (response.code === 200) {
          // 图片上传成功，返回的url存入this.alum.push()
          // Toast('上传成功')
          // console.log(response.result.url)
          that.alum = (response.result.url)
          // file.status = ''
          // file.message = ''
          that.upModel = false
          let params = {
            token: Cookies.get("spToken"),
            thumb: that.alum
          }
          console.log(params)
          that.$api.my.editThumb(params).then(function (response) {
            if (response.code === 200) {
              Toast('更换成功')
              that.dialogShow = false
              that.getInfo();
            }
          })
        } else {
          // file.status = 'failed'
          // file.message = '上传失败'
        }
      })
    },
    handleRemove (file) {
      // console.log(file)
      // console.log(this.fileList.indexOf(file))
      let idx = this.fileList.indexOf(file)
      this.fileList.splice(idx, 1)
      this.alum.splice(idx, 1)
    },
    toPublic() {
      if (this.role.showFabuBtn) {
        this.toPath("/articlePub", {});
      } else {
        // 没有权限
        Dialog({ message: "您暂时没有发布权限", confirmButtonColor: "#0b47ac" });
      }
    },
    shareFun() {
      var that = this;
      var tempurl = location.href;
      const url = location.href.split("#")[0];
      console.log("Iframe ---share----url=" + url);
      let routeData = that.$router.resolve({
        path: "/share",
        query: {
          speaker_id: that.myInfo.id
        }
      });
      const shareUrl = that.$publicData.domain + routeData.href;
      console.log(shareUrl);
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // console.log('Iframe ---微信分享设置---开始');
          // 分享
          wx.ready(function() {
            var shareDataA = {
              // title: that.$publicData.shareTitle, // 分享标题
              title: "我已成为运城发声人，我为家乡代言", // 分享标题
              desc: that.$publicData.shareDesc, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              // title: that.$publicData.shareTitle, // 分享标题
              title: "我已成为运城发声人，我为家乡代言", // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
            // console.log('Iframe ---微信分享设置---结束');
          });
        }
      });
    },
    roleCon() {
      let priv = JSON.parse(localStorage.getItem("priv")) || [];
      if (priv.length > 0) {
        let arr = [];
        priv.forEach(function(item, idx) {
          arr.push(item.right.id);
        });
        if (arr.indexOf(1) != -1) {
          this.role.showTask = true;
        } else {
          this.role.showTask = false;
        }
        if (arr.indexOf(6) != -1) {
          this.role.showFabu = true;
        } else {
          this.role.showFabu = false;
        }
        if (arr.indexOf(7) != -1) {
          this.role.showFabuBtn = true;
        } else {
          this.role.showFabuBtn = false;
        }
      }
    },
    memberInfo() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.index.memberInfo(ps).then(function(response) {
        if (response.code === 200) {
          localStorage.setItem("priv", JSON.stringify(response.result.priv));
          that.roleCon();
          if (
            response.result.speaker_id == 0 &&
            response.result.speaker == null
          ) {
            that.toPath("/apply", {});
          } else if (
            response.result.speaker &&
            response.result.speaker.status == 0
          ) {
            // 已提交申请
            Dialog.confirm({
              title: "",
              message: "您已提交申请，请耐心等待。",
              confirmButtonColor: "#0b47ac"
            })
              .then(() => {
                // on confirm
                console.log("确定");
              })
              .catch(() => {
                // on cancel
                console.log("cancel");
              });
          } else if (
            response.result.speaker &&
            response.result.speaker.status == -1
          ) {
            // 驳回，remark原因
            Dialog.confirm({
              title: "",
              message: "审核未通过,原因：" + response.result.speaker.remark,
              confirmButtonColor: "#0b47ac"
            })
              .then(() => {
                // on confirm
                console.log("确定，跳转到申请页面");
                that.toPath("/apply", {});
              })
              .catch(() => {
                // on cancel
                console.log("cancel");
              });
          } else if (
            response.result.speaker &&
            response.result.speaker.status == 1
          ) {
            // 审核通过
            that.toPath("/my", {});
          }
        }
      });
    },
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    roleChangeLog() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.my.roleChangeLog(ps).then(function(response) {
        if (response.code === 200) {
          if (response.result) {
            that.loadRole = true;
            that.roleData = response.result;
          }
        }
      });
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.list = [];
      that.loading = true;
      that.finished = false;
      that.params.page = 1;
      that.onLoad();
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    onClickTab(e) {
      let that = this;

      that.list = [];
      that.loading = true;
      that.finished = false;
      that.params.page = 1;
      that.onLoad();
    },
    getInfo() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.my.detail(ps).then(function(response) {
        that.loadShow = false;
        if (response.code === 200) {
          // console.log(response.result)
          that.myInfo = response.result;
          Cookies.set("kyscore", that.myInfo.score, { expires: 365 });
          that.shareFun();
        }
      });
    },
    onLoad() {
      let that = this;
      var token = Cookies.get("spToken");
      if (!token) {
        return;
      }
      if (!that.active) {
        return;
      }
      that.params.type = that.active;
      that.params.token = Cookies.get("spToken");
      that.$api.my.articleList(that.params).then(function(response) {
        if (response.code === 200) {
          // console.log(response);
          that.list = that.list.concat(response.result);
          that.params.page++;
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          // console.log(response.result.length)
          if (response.result.length < 1) {
            that.finished = true;
          }
        }
      });
    },
    getLocation() {
      var that = this;
      var tempurl = location.href;
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          wx.ready(function() {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed; // 速度，以米/每秒计
                // var accuracy = res.accuracy; // 位置精度
                // console.log(res)
                console.log('获取成功')
                let locationData = {
                  token: Cookies.get("spToken"),
                  lat: latitude,
                  lng: longitude
                }
                that.$api.index.speakerEditArea(locationData).then(res => {
                  if (res.code == 200) {
                    // 更改
                  }
                })
              }
            })
          })
        }
      })
    }
  }
};
</script>

<style>
.personal .article .articleItem .state {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0;
  border-bottom: 1px solid #ebedf0;
}
.personal .article .articleItem .state .desc {
  display: inline-flex;
  align-items: center;
  font-size: 0.35rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333;
}
.personal .article .articleItem .state .van-tag {
  font-family: PingFang SC;
  font-weight: 400;
}
.personal .release {
  position: relative;
}
.personal .release .control {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(11, 71, 172, 0.6);
  font-size: 0.37rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.95);
}
.personal .no-check {
  padding: 2rem 0;
  text-align: center;
}
.personal .no-check img {
  width: 3.8rem;
  height: 2.36rem;
}
.personal .no-check h1 {
  font-size: 0.37rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.roleChange-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.roleChange-block {
  width: 7.96rem;
  height: 10.11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../public/statics/images/my/rolebg.png);
  background-size: 100% 100%;
  padding-top: 1.35rem;
  position: relative;
}
.roleChange-block .avator {
  width: 1.86rem;
  height: 1.87rem;
  border-radius: 50%;
}
.roleChange-block h1 {
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-top: 0.2rem;
}
.roleChange-block h2 {
  text-align: center;
  font-size: 0.51rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-top: 0.6rem;
}
.roleChange-close {
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 0.6rem;
}
.personal .article .articleItem.fabu .artTop:after {
  display: none;
}
</style>