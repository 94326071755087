<template>
  <div class="articleDetail">
    <!-- 顶部导航 -->
    <!-- <van-nav-bar class="topnav" title="原创文章" left-arrow @click-left="toBack" :border="false" /> -->
    <!-- 底部导航 -->
    <div class="artHead">
      <h1 class="title">{{viewData.title}}</h1>
      <div class="flexBox">
        <div class="boxLeft">
          <!-- <img src="../../public/statics/images/articleDetail/avator.png"> -->
          <img :src="adddom(viewData.speaker ? viewData.speaker.thumb : '')" />
          <div>
            <h2>{{viewData.speaker ? viewData.speaker.name : ''}}</h2>
            <p>提交时间：{{viewData.created_at}}</p>
          </div>
        </div>
        <div class="boxRight">浏览量:{{viewData.hit}}人</div>
      </div>
    </div>
    <!-- <img class="pic" src="../../public/statics/images/task/detail_1.jpg"> -->
    <!-- <img class="pic" :src="adddom(viewData.thumb)" /> -->
    <div class="cont" v-html="viewData.content"></div>
    <!-- <p class="cont">“我为冬奥加油”青少年迷你冬奥会绘画作品展在京举办。</p>
    <p class="cont">为进一步激发青少年参与冬奥的热情，落实“以运动员为中心”的理念，北京市关心下一代工作委员会、北京冬奥组委新闻宣传部等有关单位，面向全国中小学生开展冬奥主题青少年绘画作品征集活动。</p>
    <p class="cont">学生们围绕“卓越、尊重、友谊”的奥林匹克核心价值观，“勇气、毅力、激励、平等”的残奥核心价值观，世界和平、友谊和团结等主题，以北京2022年冬奥会和冬残奥会相关知识、运动项目、春节和长城等传统文化和地域风貌为内容，表达对健康体魄、乐观精神的赞美，对美好生活的热爱和对参赛运动员的新年祝福。</p>-->
    <div class="state" v-show="false">
      <img src="../../public/statics/images/articleDetail/state.png" />
      <p class="desc">文章正在审核中...</p>
    </div>
    <div class="taskDesc" v-show="false">
      <div class="taskLeft">
        <img src="../../public/statics/images/articleDetail/task.png" />
        <div>
          <h2>单次发布原创任务</h2>
          <p>积分:10</p>
        </div>
      </div>
      <div class="taskRight">
        <van-button class="btn" plain round type="primary">待领取</van-button>
      </div>
    </div>
    <!-- <index-footer></index-footer> -->
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      viewData: "",
      loadShow: false,
      shareUrl: require("../../public/statics/images/share.jpg"),
    };
  },
  components: {
    indexFooter
  },
  created() {
    this.loadShow = true;
    this.getDetail();
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getDetail() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken"),
        id: this.$route.query.id,
      };
      that.$api.article.detail(ps).then(res => {
        that.loadShow = false;
        if (res.code == 200) {
          // console.log(res.result)
          that.viewData = res.result;
          window.document.title = that.viewData.title;
          // 分享
          let routeData = that.$router.resolve({
            path: '/articleShare',
            query: {
              id: that.viewData.id,
              share_speaker_id: Cookies.get("id")
            }
          })
          let tempurl = location.href;
          let shareUrl = location.href.split("#")[0] + routeData.href
          console.log(shareUrl)
          that.$api.index.getShare({url:tempurl}).then(function(res){
            if (res.code == 200) {
              let shareConfig = res.result;
              console.log(shareConfig);
              wx.config({
                debug: shareConfig.debug,
                beta: shareConfig.beta,
                jsApiList: shareConfig.jsApiList,
                appId: shareConfig.appId,
                nonceStr: shareConfig.nonceStr, // 随机串
                timestamp: parseInt(shareConfig.timestamp), // 时间戳
                signature: shareConfig.signature // 签名
              });
              wx.ready(function () {
                var shareDataA = {
                  title: that.viewData.title, // 分享标题
                  desc: that.viewData.content, // 分享描述
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.viewData.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                var shareDataB = {
                  title: that.viewData.title, // 分享标题
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.viewData.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                setTimeout(function(){
                  wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                  wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                },2000)
              })
            }
          })
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>