<template>
  <div class="specialList">
    <!-- 标签 -->
    <img style="width:100%;height: 4.53rem;display:block;" :src="adddom(cateList.thumb)" alt />
    <h1 v-if="cateList.name_status == 1" class="title">{{cateList.name}}</h1>
    <!-- 专题列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div class="spec_list">
      <van-list
        v-model:loading="loading"
        class="article1"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
      >
        <div class="spec_item" @click="toDetail(item)" v-for="(item,index) in list" :key="'cate'+index">
          <div class="desc">
            <h1>{{item.title}}</h1>
            <div>
              {{timestampToTime(item.created_at, 1)}}
              <!-- <em>{{item.ling_num > 10000 ? (item.ling_num/10000).toFixed(1)+' 万': item.ling_num}}人转发</em> -->
              <p>{{item.hit > 10000 ? (item.hit/10000).toFixed(1)+' 万': item.hit}} 人已阅</p>
            </div>
          </div>
          <div class="pic" style="margin:0;margin-left:0.5rem;">
            <img style="height:100%;width:100%;object-fit: cover;" :src="adddom(item.thumb)" alt />
            <!-- <img src="../../public/statics/images/index/1.jpg" alt /> -->
          </div>
        </div>
      </van-list>
      <van-empty v-if="!loading && list.length == 0" description="暂无数据" />
    </div>
    </van-pull-refresh>
    <!-- 底部导航 -->
    <index-footer></index-footer>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import indexFooter from "./footer.vue";
import utils from '@/api/Utils'
export default {
  data() {
    return {
      cateList: [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        cate_id: "",
        page: 1,
        size: 10
      },
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode?url=worldlList",
      memberUrl: "http://speaker.shanmuzhi.com/#/worldlList"
    };
  },
  props:[],
  components: {
    indexFooter
  },
  mounted() {
    this.shareFun()
  },
  created() {
    let that = this
    // 分享
    this.getCodeUrl = this.$publicData.domain + "api/wxcode?url=worldlList";
    this.memberUrl = this.$publicData.domain + "#/worldlList";
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      // 有token 直接展示
      // this.onLoad()
      return;
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
      var code = codeArr["code"];
      var params = {
        code: code
      };
      // 绑定
      this.$api.index.wxlogin(params).then(res => {
        if (res.code == 200) {
          Cookies.set("spToken", res.result.token, { expires: 365 });
          localStorage.setItem("priv", JSON.stringify(res.result.priv));
          that.onLoad();
          window.history.replaceState(null, null, this.memberUrl);
        }
      });
    } else {
      window.location.href = this.getCodeUrl;
    }
  },
  watch: {
  },
  methods: {
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    shareFun() {
      var that = this;
      var tempurl = location.href;
      const url = location.href.split("#")[0];
      const shareUrl = that.memberUrl || location.href;
      console.log("Iframe ---share----url=" + shareUrl);
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // console.log('Iframe ---微信分享设置---开始');
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.$publicData.shareTitle, // 分享标题
              desc: that.$publicData.shareDesc, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              title: that.$publicData.shareTitle, // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
            // console.log('Iframe ---微信分享设置---结束');
          });
        }
      });
    },
    timestampToTime (timestamp, type) {
      if (!timestamp) return
      return utils.timestampToTime(timestamp, type)
    },
    toDetail(item) {
      // console.log(item.id)
      this.toPath('/taskDetail',{id: item.id})
    },
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    onRefresh() {
      let that = this
      that.refreshing = false;
      that.list = []
      that.loading = true;
      that.finished = false;
      that.params.page = 1
      that.onLoad()
    },
    onLoad() {
      let that = this
      that.params.token = Cookies.get('spToken')
      if(!that.params.token){
        return
      }
      that.$api.index.shijie(that.params).then(function (response) {
        if (response.code === 200) {
          console.log(response)
          that.cateList = response.result.cate
          that.list = that.list.concat(response.result.list)
          that.params.page++
          // 加载状态结束
          that.loading = false
          // 数据全部加载完成
          // console.log(response.result.length)
          if (response.result.list.length < 1) {
            that.finished = true
          }
        }
      })
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    },
  }
};
</script>

<style scoped>
.specialList{
  width: 100%;
}
.specialList .title{
  padding: 0 0.4rem;
  padding-top: 0.4rem;
  font-size: 0.45rem;
  line-height: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
}
.specialList .spec_list {
    margin: 0.4rem 0.4rem 3rem;
}
.specialList .spec_list .spec_item{
  margin-bottom: 0.4rem;
  box-shadow: 0rem 0.13rem 0.47rem 0rem rgb(50 93 205 / 15%);
  box-shadow: none;
  border-radius: 0.13rem;
  padding: 0rem;
  display: flex;
  justify-content: space-between;
}
.specialList .spec_list .spec_item .pic{
  width: 2.88rem;
  height: 1.86rem;
  margin-left: 0.8rem;
}
.specialList .spec_list .spec_item .desc{
  flex: 1;
  position: relative;
}
.specialList .spec_list .spec_item .desc h1{
  font-size: 0.37rem;
  line-height: 0.55rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.specialList .spec_list .spec_item .desc div{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.3rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
/* .specialList .spec_list .spec_item .desc p{
  display: inline-block;
  line-height: 0.6rem;
  background: #F6F6F6;
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 0.2rem;
  margin-top: 0.15rem;
} */
</style>