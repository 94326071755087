<template>
  <div class="articleDetail">
    <!-- 顶部导航 -->
    <!-- <van-nav-bar class="topnav" title="原创文章" left-arrow @click-left="toBack" :border="false" /> -->
    <!-- 底部导航 -->
    <div class="artHead">
      <h1 class="title" v-if="$route.query.type == 1">{{mydetail.title}}</h1>
      <h1 class="title" v-if="$route.query.type == 2">{{mydetail.title}}</h1>
      <h1 class="title" v-if="$route.query.type == 3">{{mydetail.duty ? mydetail.duty.title : ''}}</h1>
      <div class="flexBox">
        <div class="boxLeft">
          <img
            v-if="$route.query.type == 1"
            :src="adddom(mydetail.speaker ? mydetail.speaker.thumb : '')"
          />
          <div>
            <h2 v-if="$route.query.type == 1">{{mydetail.speaker.name}}</h2>
            <!-- <p>提交时间：{{mydetail.created_at}}</p> -->
          </div>
        </div>
        <div
          class="boxRight"
          v-if="$route.query.type == 1 || $route.query.type == 2"
        >浏览量:{{mydetail.hit}}人</div>
        <div
          class="boxRight"
          v-if="$route.query.type == 3"
        >浏览量:{{mydetail.duty ? mydetail.duty.hit : ''}}人</div>
      </div>
    </div>
    <!-- <img class="pic" v-if="$route.query.type == 1" :src="adddom(mydetail.thumb)" />
    <img class="pic" v-if="$route.query.type == 2" :src="adddom(mydetail.thumb)" /> -->
    <!-- <img
      class="pic"
      v-if="$route.query.type == 3"
      :src="adddom(mydetail.duty ? mydetail.duty.thumb : '')"
    /> -->
    <div class="cont" v-if="$route.query.type == 1" v-html="mydetail.content"></div>
    <div class="cont" v-if="$route.query.type == 2" v-html="mydetail.content"></div>
    <div
      class="cont"
      v-if="$route.query.type == 3"
      v-html="mydetail.duty ? mydetail.duty.content : ''"
    ></div>
    <div class="state" v-show="$route.query.type == 1">
      <img src="../../public/statics/images/articleDetail/state.png" />
      <p class="desc">{{mydetail.status==-1?'文章被驳回':mydetail.status==0?'文章正在审核中...':'文章已通过'}}</p>
    </div>
    <!-- 只显示，领取和已领取。不显示去完成 -->
    <div class="taskDesc" v-show="item.log && !(item.log.can_get == 0 && item.log.score_status == 0)" v-for="(item,index) in mydetail.task" :key="index">
      <div class="taskLeft">
        <img src="../../public/statics/images/articleDetail/task.png" />
        <div>
          <h2>{{item.name}}</h2>
          <p>积分:{{item.score}}</p>
        </div>
      </div>
      <div class="taskRight">
        <van-button
          class="btn"
          plain
          round
          type="primary"
          v-if="item.log && item.log.can_get==1 && item.log.score_status ==0"
          @click="receive(item)"
        >领取</van-button>
        <van-button
          class="btn"
          plain
          round
          type="primary"
          v-else-if="item.log && item.log.score_status ==1"
        >已领取</van-button>
        <van-button
          class="btn"
          plain
          round
          type="primary"
          v-else="item.log && item.log.can_get==0"
        >去完成</van-button>
      </div>
    </div>
    <div style="height:3rem;"></div>
    <index-footer></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
import { Notify } from "vant";
export default {
  data() {
    return {
      mydetail: {
        task: [],
        speaker: {},
        loadShow:false,
      } //文章详情
    };
  },
  components: {
    indexFooter
  },
  created() {
    this.loadShow = true;
    this.getDetail();
  },
  methods: {
    receive(item) {
      let that = this;
      console.log(item);
      let ps = {
        token: Cookies.get("spToken"),
        task_id: item.id,
        log_id: item.log.id
      };
      console.log(ps)
      that.$api.article.score(ps).then(res => {
        if (res.code == 200) {
          // console.log(res.result)
          // that.list = res.result
          // Notify({ type: "success", message: "领取成功" })
          that.$toast("领取成功")
          that.getDetail()
        }
      });
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getDetail() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken"),
        id: this.$route.query.id
      };
      if (this.$route.query.type == 1) {
        that.$api.article.myDetail(ps).then(function(response) {
          that.loadShow = false;
          if (response.code === 200) {
            // console.log(response.result)
            that.mydetail = response.result;
            window.document.title = that.mydetail.title;
            that.shareHandel()
          }
        });
      }
      if (this.$route.query.type == 2) {
        that.$api.article.zhuanDetail(ps).then(function(response) {
          that.loadShow = false;
          if (response.code === 200) {
            // console.log(response.result)
            that.mydetail = response.result;
            window.document.title = that.mydetail.title;
            that.shareHandel()
          }
        });
      }
      if (this.$route.query.type == 3) {
        that.$api.index.myDetail(ps).then(function(response) {
          that.loadShow = false;
          if (response.code === 200) {
            // console.log(response.result)
            that.mydetail = response.result;
            window.document.title = that.mydetail.duty.title;
            that.shareHandel()
          }
        });
      }
    },
    shareHandel() {
      // 分享
      let that = this
      let url = that.$route.query.type == 3 ? '/taskShare' : '/articleShare'
      let routeData = that.$router.resolve({
        path: url,
        query: {
          id: that.mydetail.id,
          share_speaker_id: Cookies.get("id")
        }
      })
      if (this.$route.query.type == 3) {
        routeData = that.$router.resolve({
          path: url,
          query: {url: that.mydetail.url}
        })
      } else {}
      let tempurl = location.href;
      let shareUrl = location.href.split("#")[0] + routeData.href
      console.log(shareUrl)
      that.$api.index.getShare({url:tempurl}).then(function(res){
        if (res.code == 200) {
          let shareConfig = res.result;
          console.log(shareConfig);
          wx.config({
            debug: shareConfig.debug,
            beta: shareConfig.beta,
            jsApiList: shareConfig.jsApiList,
            appId: shareConfig.appId,
            nonceStr: shareConfig.nonceStr, // 随机串
            timestamp: parseInt(shareConfig.timestamp), // 时间戳
            signature: shareConfig.signature // 签名
          });
          wx.ready(function () {
            var shareDataA = {
              title: that.$route.query.type == 3 ? that.mydetail.duty.title : that.mydetail.title, // 分享标题
              desc: that.$route.query.type == 3 ? that.mydetail.duty.describe : that.mydetail.content, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.adddom(that.$route.query.type == 3 ? that.mydetail.duty.thumb : that.mydetail.thumb) || that.$publicData.shareImg, // 分享图标
              success: function () {
              },
              cancel: function (e) {
              }
            }
            var shareDataB = {
              title: that.$route.query.type == 3 ? that.mydetail.duty.title : that.mydetail.title, // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.adddom(that.$route.query.type == 3 ? that.mydetail.duty.thumb : that.mydetail.thumb) || that.$publicData.shareImg, // 分享图标
              success: function () {
              },
              cancel: function (e) {
              }
            }
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          })
        }
      })
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.articleDetail {
  width: 100%;
  min-height: 100vh;
  padding: 0.52rem 0.4rem;
  padding-bottom: 1.5rem;
}
.articleDetail .artHead .title {
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  line-height: 1.46;
}
.articleDetail .artHead .flexBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
}
.articleDetail .artHead .flexBox .boxLeft {
  display: inline-flex;
  align-items: center;
}
.articleDetail .artHead .flexBox .boxLeft img {
  width: 0.93rem;
  height: 0.93rem;
  border-radius: 50%;
  margin-right: 0.2rem;
}
.articleDetail .artHead .flexBox .boxLeft h2 {
  font-size: 0.35rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
}
.articleDetail .artHead .flexBox .boxLeft p {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.articleDetail .artHead .flexBox .boxRight {
  line-height: 0.54rem;
  background: #f6f6f6;
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 0.1rem;
}
.articleDetail .cont {
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-top: 0.3rem;
}
.articleDetail .pic {
  display: block;
}
.articleDetail .state {
  text-align: center;
  margin-top: 0.5rem;
}
.articleDetail .state img {
  width: 2.63rem;
  height: 2.57rem;
}
.articleDetail .state p {
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-top: 0.2rem;
}
.articleDetail .taskDesc {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  align-items: center;
  box-shadow: 0 0 20px 0 rgb(50 93 205 / 15%);
  padding: 0.2rem 0.3rem;
  margin-top: 0.5rem;
}
.articleDetail .taskDesc .taskLeft {
  display: inline-flex;
  align-items: center;
}
.articleDetail .taskDesc .taskLeft img {
  height: 1.4rem;
  margin-right: 0.25rem;
}
.articleDetail .taskDesc .taskLeft h2 {
  font-size: 0.43rem;
}
.articleDetail .taskDesc .taskRight .btn {
  width: 1.61rem;
  height: 0.59rem;
  border: 1px solid #0b47ac;
  border-radius: 10px;
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0b47ac;
  padding: 0;
}
</style>